import React from 'react'

export default function WhyUblocks() {
    return (
        <div id="why">
            <div className="container" id='why-content'>
                <div className="row py-5">
                    <div className="col-md-12">
                        <div>
                            <h2 className='text-uppercase text-purple text-center'>Why Ublocks?</h2>
                            <p className='text-purple-dark text-center'>Tools for brands and companies. Onboard your users with our easy to implement solutions
                                and experience the full potential of the blockchain.</p>
                        </div>
                        <div className="card p-5">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="d-flex flex-column align-items-center">
                                        <h4>Fast and global</h4>
                                        <img src="/images/fast.png" alt="Fast and global" style={{ maxWidth: '100px' }} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="d-flex flex-column align-items-center">
                                        <h4>Guaranteed Security</h4>
                                        <img src="/images/secure.png" alt="Guaranteed Security" style={{ maxWidth: '100px' }} />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="d-flex flex-column align-items-center">
                                        <h4>Technical Support</h4>
                                        <img src="/images/support.png" alt="Technical Support" style={{ maxWidth: '100px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
