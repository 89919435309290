import React from 'react'
import { Link } from 'react-router-dom'
import CTABG from '../../Assets/cta-bg.jpg'
export default function CTA() {
    return (
        <div className='cta card rounded p-5' style={{ backgroundImage: `URL(${CTABG})` }}>
            <div className="row py-5">
                <div className="col-md-12 text-center text-white">
                    <h5 className='text-warning'>Let's do something tougher!</h5>
                    <h2>Have a project idea in your mind?</h2>
                    <p className='text-white'>We can help you to create your dream website for better business revenue.</p>
                    <Link to="/contact" className='btn btn-warning fs-bold'>Contact us</Link>
                </div>
            </div>
        </div>
    )
}
