import React from 'react'

export default function Works() {
    return (
        <div className='overflow-hidden page'>
            <div className='container'>
                <div className="row py-3">
                    <div className="col-md-12">
                        <h2>uBlocks Technologies Works</h2>
                        <p>Web3, also known as "Web 3.0" or the "Decentralized Web," refers to a new generation of the Internet that aims to create a decentralized and open web.
                            Unlike Web 1.0, which was focused on static web pages, and Web 2.0, which emphasized user-generated content and social networking, Web3 aims to
                            create a more user-centric, transparent, and secure web by leveraging blockchain technology and decentralized protocols.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
