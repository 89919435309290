import icon1 from '../../Assets/graphics/icon-1.png'
import icon2 from '../../Assets/graphics/icon-2.png'
import titleBottom from '../../Assets/graphics/title-bottom.png'

export default function Intro() {
    return (
        <div id="intro">
            <div className="container">
                <div className="icon-1">
                    <img src={icon1} alt="icon" />
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 d-flex justify-content-center">
                        <div className="content text-center">
                            <div className='main-title'>
                                <span className="heading-addon">We Are</span>
                                <h2 className='text-uppercase'>Your <span>Web3</span> Partner</h2>
                                <div className="title-bottom">
                                    <img src={titleBottom} alt="title-bottom" />
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <p>We help start-ups, businesses and companies to design more transparent,
                                        efficient and automated version of their businesses with our wide spectrum
                                        of blockchain development services</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="icon-2">
                    <img src={icon2} alt="icon" />
                </div>
            </div>
            <div className='intro-bottom'></div>
        </div>
    )
}
