import Intro from '../../components/Home/Intro';
import SectionTwo from '../../components/Home/SectionTwo';
import Services from '../../components/Home/Services';
import WhyUblocks from '../../components/Home/WhyUblocks';

export default function Home() {
    return (
        <div id="home" className='overflow-hidden page'>
            <Intro />
            <SectionTwo />
            {/* <Services /> */}
            <WhyUblocks />
            {/* <Clients /> */}
        </div>
    )
}