import React from 'react'

export default function SectionTwo() {
    return (
        <div id="section2">
            <div className="container">
                <div className="row">
                    <h2 className='text-center text-white text-uppercase mb-2'>Value Proposition</h2>
                    <div className="description">
                        <p className='text-center'>We <span className='text-info'>provide</span> easy-to-use <span className='text-warning'>Web3 development tools</span> and <span className='text-warning'>frameworks</span></p>
                        <p className='text-center'>We do <span className='text-info'>consulting</span> and <span className='text-info'>training</span> services to help businesses understand and adopt <span className='text-warning'>Web3 technology</span></p>
                        <p className='text-center'>We <span className='text-info'>develop</span> innovative <span className='text-warning'>Web3 products</span> and services that meet the needs of the market</p>
                        <p className='text-center'>We <span className='text-info'>provide</span> investment opportunities in <span className='text-warning'>Web3 projects</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
