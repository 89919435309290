import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Footer from "./components/layouts/Footer";
import Header from "./components/layouts/Header";
import About from "./pages/About/About";
import Contact from "./pages/contact/Contact";
import Home from './pages/home/Home';
import Works from "./pages/work/Works";
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/works" exact element={<Works />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
