import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import './Layout.css'

export default function Header() {
    return (
        <div className='header'>
            <div className="container">
                <Navbar expand="lg" className='mt-2 py-0'>
                    <Container>
                        <Navbar.Brand>
                            <Link to='/'><img src="/images/logo.png" alt="uBlocks" className='logo' /></Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <Link to='/' className='nav-link'>Home</Link>
                                <Link to='/about' className='nav-link'>About</Link>
                                {/* <Link to='/works' className='nav-link'>Work</Link> */}
                                <Link to='/contact' className='btn btn-primary btn-sm rounded-pill nav-link mb-2 mb-md-0'>Contact Us</Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </div>
    )
}
