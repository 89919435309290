import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <footer className='py-3'>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <img src='/images/logo.png' alt='ublocks' style={{ height: '50px' }} />
                        <p>&copy; {new Date().getFullYear()} All rights reserved by ublocks, Tecnologias de Informação, Lda.</p>
                    </div>
                    <div className="col-md-3">
                        {/* <h4>Contact</h4>
                        <ul className='list-group list-group-flush'>
                            <li className='list-group-item bg-transparent'><a href='tel:+351123456789'>+351123456789</a></li>
                            <li className='list-group-item bg-transparent'><a href='mailto:info@ublocks.xyz'>samir.jamal@ublocks.xyz</a></li>
                        </ul> */}
                    </div>
                    <div className="col-md-3">
                        <h4>Company</h4>
                        <ul className='nav flex-column'>
                            <li><Link to='/about' className='nav-item'>About</Link></li>
                            <li><Link to='/contact' className='nav-item'>Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
