import React from 'react'
import CTA from '../../components/Common/CTA'

export default function About() {
    return (
        <div className='overflow-hidden page'>
            <div className='container'>
                <div className="row py-3">
                    <div className="col-md-12 px-5">
                        <h2 className='mt-3'>About Us</h2>
                        <div className="description py-2">
                            <p>Welcome to ublocks! We are a cutting-edge technology company at the forefront of the Web3 revolution, specializing in blockchain solutions, smart contracts, decentralized applications (dApps), and non-fungible tokens (NFTs).</p>
                            <p>At ublocks, our mission is to empower individuals and businesses with the transformative potential of Web3 technologies. We believe in the power of decentralization and its ability to reshape the way we interact, transact, and create value in the digital world.</p>
                            <p>With our expertise in blockchain technology, we develop innovative solutions that leverage the security, transparency, and efficiency of decentralized networks. Whether you are looking to build a decentralized application, explore the world of NFTs, or integrate smart contracts into your business processes, we have the expertise and experience to turn your vision into reality.</p>
                            <p>Our team of skilled professionals is dedicated to delivering exceptional results and staying at the forefront of the rapidly evolving Web3 landscape. We combine our deep understanding of blockchain technology with a passion for innovation to provide tailor-made solutions that meet your specific needs.</p>
                            <p>At ublocks, we believe in collaboration and community. We actively contribute to the open-source ecosystem and work closely with our clients to ensure their success in the Web3 era. We value transparency, integrity, and trust, and strive to build long-term partnerships based on these principles.</p>
                            <p>Join us on this exciting journey as we unlock the full potential of Web3, revolutionize industries, and shape the future of decentralized technologies. Contact us today to explore how ublocks can help you harness the power of blockchain, smart contracts, dApps, and NFTs to drive your business forward.</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <CTA />
                    </div>
                </div>
            </div>
        </div>
    )
}
