import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useState } from 'react';

export default function Contact() {
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE;
    const EmailJsAPI = process.env.REACT_APP_EMAILJS_API;
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const form = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(serviceId, templateId, form.current, EmailJsAPI)
            .then(
                (result) => {
                    if (result.text === 'OK') {
                        setSuccessMessage('Your Message has been sent successfully.');
                        form.current.reset();
                        setTimeout(() => {
                            setSuccessMessage('');
                        }, 5000);
                    }
                },
                (error) => {
                    console.log(error.text)
                    setErrorMessage('An error occurred while sending the message. Please try again later.');
                    setTimeout(() => {
                        setErrorMessage('');
                    }, 5000);
                }
            );
    };

    return (
        <div className="overflow-hidden page">
            <div className="container">
                <div className="row py-5">
                    <div className="col-md-12">
                        <h2>Talk to Our Sales & Marketing Department Team</h2>
                        <p></p>
                        <div className="row">
                            <div className="col-md-6">
                                <img src="/images/contact-us-img.svg" alt="contact us" className="img-fluid" />
                            </div>
                            <div className="col-md-6">
                                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                <form ref={form} onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name">Your Name</label>
                                        <input type="text" name="from_name" id="name" className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email">Your Email</label>
                                        <input type="email" name="user_email" id="email" className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="subject">Subject</label>
                                        <input type="text" name="subject" id="subject" className="form-control" required />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="message">Your Message</label>
                                        <textarea name="message" id="message" cols="30" rows="10" className="form-control"></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}